import React from 'react';
import { GFImage } from '@gf/cross-platform-lib/components';
import { Text, TouchableOpacity, View } from 'react-native';
import { School } from '@gf/cross-platform-lib/interfaces';
import { Fan } from '@gf/cross-platform-lib/models';
import styled from '@gf/cross-platform-lib/styled-components';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import { titleCase, homePage, testProperties } from '@gf/cross-platform-lib/utils';
import { SchoolDistanceTitle } from '../SchoolDistanceTitle';
import { styles } from './SearchResultItem.styles';
import { GestureResponderEvent } from 'react-native';
import { FILTER_SCHOOLS } from '@gf/cross-platform-lib/constants';
import { useSearchPageContext } from '@gf/cross-platform-lib/providers';
import { numberWithCommas } from '@gf/cross-platform-lib/utils/numberWithCommas';
import { useRouter, useTracking } from '@gf/cross-platform-lib/hooks';
import { useFetchSchool } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { ToggleFavorite } from '@gf/cross-platform-lib/modules/school/components/ToggleFavorite/ToggleFavorite';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const LogoSection = styled(View)`
  ${styles.logoSection};
`;

const DescriptionSection = styled(View)`
  ${styles.descriptionSection};
`;

const Title = styled(Text)`
  ${styles.title}
`;

const SubTitleView = styled(View)<{ showColumn: boolean }>`
  flex-direction: ${({ showColumn = false }) => (showColumn ? 'column' : 'row')};
  align-items: flex-start;
`;

const Subtitle = styled(Text)`
  ${styles.subtitle}
`;

const FavorItem = styled(View)`
  ${styles.favorItem};
`;

const IconContainer = styled(View)`
  ${styles.iconContainer}
`;

const DistanceTitle = styled(Text)`
  color: #5b5b5b;
  font-family: Rubik-Regular;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.32px;
  line-height: 16px;
`;

export type SchoolRowItemProps = {
  huddleID: string;
  itemId: string;
  subtitle?: string;
  onItemClick?: (id: string) => void;
  isFavorite: boolean;
  onToggleFavorite?: (id: string, name: string) => void;
  lastItem: boolean;
  searchCategory?: string;
  filterSchools?: School[];
  applyFilter?: (type: string, selected: boolean, params: any) => void;
  displaySchoolSearch?: boolean;
};

export const SearchResultItem = ({
  huddleID,
  itemId,
  subtitle,
  onItemClick,
  isFavorite,
  onToggleFavorite,
  lastItem,
  searchCategory,
  filterSchools,
  applyFilter,
  displaySchoolSearch
}: SchoolRowItemProps) => {
  const { trackSearchResultSelected, identifyFavoriteSchool, trackFavoriteEvent, trackUnFavoriteEvent } = useTracking();
  const { setActive } = useSearchPageContext();
  const { data } = useFetchSchool(huddleID);
  const school = data?.data;
  const imgSrc = school?.logoUrl ? `${school?.logoUrl}` : 'gofan-icon.png';
  const title = school?.customPageName || school?.name;
  const router = useRouter();

  const distance =
    (!isEmpty(filterSchools) && filterSchools!.find(school => school.huddleId === huddleID)?.distance) || 0;
  const formattedDistance = numberWithCommas(distance);

  let _subtitle = subtitle;
  let latitude;
  let longitude;
  if (!subtitle) {
    latitude = school?.latitude || undefined;
    longitude = school?.longitude || undefined;
    _subtitle = `${
      school ? `${school.city ? `${titleCase(school.city)}, ` : ''}${school.state?.toUpperCase() || ''}` : ' '
    }`;
  }

  const onHandleFavorite = async (e: GestureResponderEvent) => {
    e.stopPropagation();
    if (onToggleFavorite) {
      onToggleFavorite(itemId, title!);
      await Fan.getInstance().toggleFavorite(itemId, title!);
      identifyFavoriteSchool(Fan.getInstance());
      const isFavorite = Fan.getInstance().favIDsToSchoolName && Fan.getInstance().favIDsToSchoolName[itemId];
      if (isFavorite) {
        trackFavoriteEvent(router.currentRoute, {
          orgId: school?.huddleId || '',
          orgType: school?.gofanSchoolType || ''
        });
      } else {
        trackUnFavoriteEvent(router.currentRoute, {
          orgId: school?.huddleId || '',
          orgType: school?.gofanSchoolType || ''
        });
      }
    }
  };

  const clickSearchItem = () => {
    if (onItemClick) {
      setActive(false);
      onItemClick(itemId);
    }
    trackSearchResultSelected(router.currentRoute, {
      category: searchCategory || '',
      orgId: itemId,
      orgType: school?.gofanSchoolType || ''
    });
    if (!isEmpty(filterSchools) && typeof applyFilter === 'function' && displaySchoolSearch) {
      const selected = filterSchools!.map((e: any) => e.huddleId.toUpperCase()).includes(itemId.toUpperCase());
      applyFilter(FILTER_SCHOOLS, selected, itemId.toUpperCase());
    }
  };

  if (!school) return <></>;

  return (
    <TouchableOpacity onPress={clickSearchItem} style={{ width: '100%' }}>
      <Wrapper
        id={itemId}
        className='SearchResultItem'
        lastItem={lastItem}
        {...testProperties(homePage.searchResults.schoolItem)}
      >
        <LogoSection>
          <GFImage width={40} height={40} src={imgSrc} lazy={false} alt={'School logo'} />
        </LogoSection>
        <DescriptionSection>
          <Title {...testProperties(homePage.searchResults.schoolName)}>{title}</Title>
          <SubTitleView showColumn={displaySchoolSearch}>
            <Subtitle {...testProperties(homePage.searchResults.schoolVenue)}>{_subtitle}</Subtitle>
            {displaySchoolSearch && formattedDistance && formattedDistance !== '0' ? (
              <DistanceTitle {...testProperties(homePage.searchResults.schoolDistance)}>
                &nbsp;{`(${formattedDistance} miles away)`}
              </DistanceTitle>
            ) : (
              isNumber(latitude) &&
              isNumber(longitude) && (
                <SchoolDistanceTitle schoolLocation={{ latitude: latitude || 0, longitude: longitude || 0 }} />
              )
            )}
          </SubTitleView>
        </DescriptionSection>
        <FavorItem>
          <TouchableOpacity onPress={onHandleFavorite}>
            <IconContainer pointerEvents='none' {...testProperties(isFavorite ? 'favorited' : 'un-favorite')}>
              <ToggleFavorite
                toggleFavorite={onHandleFavorite}
                isFavorite={isFavorite}
                iconHeight='14px'
                iconWidth='14px'
              />
            </IconContainer>
          </TouchableOpacity>
        </FavorItem>
      </Wrapper>
    </TouchableOpacity>
  );
};
