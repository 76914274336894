import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  iconContainer: css<{ backgroundColor: string }>`
    padding: 6px;
    border-radius: 16px;
    background-color: ${({ backgroundColor }) => backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  wrapper: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  `
};
